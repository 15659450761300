<template>

  <div>

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'GiftCards'">
      <template v-slot:right-end>
        <div class="vx-row">
          <vs-button class="alpha-clear" v-if="selectedAlpha" color="warning" type="flat"
                     icon-pack="feather" icon="icon-x" size="small"
                     @click="clearSelectedAlpha()"></vs-button>
          <vs-pagination-modified v-model="selectedAlphaCurrentX" :total="26" :max="26"
                                  v-on:change="pageChanged"></vs-pagination-modified>
        </div>
      </template>
    </breadcrumb-base>



    <transition name="fade">
      <div class="vs-row">
        <div class="vs-col px-4 py-3 flex md:w-full">
          <div class="vs-row w-full">
            <vs-input vs-w="12" class=" m-5 w-full is-label-placeholder" icon-pack="feather" icon="icon-search"
                      :placeholder="$t('Search')" v-model="searchText"/>
            <div class="vs-col px-4 py-3 flex md:w-full">
              <div class="vs-row">
                <div class="vs-col mr-6 mb-6" v-bind:key="index" v-for="(card, index) in giftCards">
                  <vx-card class="cursor-pointer" style="width: 180px;" @click="onProductSelected(card)">
                    <img class="card-image" :src="card.product_image" :alt="card.product_name"/>
                  </vx-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!--    Empty List State -->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="giftCards.length === 0 && !errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--          <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--               class="mx-auto mb-4 max-w-full">-->
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
        </div>
      </div>
    </transition>

    <!--    Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
        </div>
      </div>
    </transition>


    <div>
      <vx-modal v-if="showProductDialog" @close="onCloseProductDialog" component-class="modal-container-7">
        <template slot="header"></template>
        <template slot="body" >
          <div class="p-6 flex flex-centered" v-if="!showPanInput" style="overflow-y: scroll;height: calc(100vh - 100px);">
            <div class="vs-row justify-center col-mx-auto  w-full">
              <div class="vx-col text-center">
                <img class="modal-card-image" :src="selectedProduct.product_image" :alt="selectedProduct.product_name"/>
                <div class="p-3"></div>
              </div>
              <div class="flex flex-wrap w-full">
                <div class="w-full lg:w-1/4 p-5" v-for="(product, index) in selectedProduct.product_configurations">
                  <vx-card class="rounded-md"  v-bind:class="{ 'product-selected': selectedConfigIndex == index }"  @click="setProductConfigSelected(index)">
                    <div class="vx-row vs-align-center text-center">
                      <h1 class="mx-3 item-card-text">{{ product.value | germanNumberFormat }}</h1>
                      <vs-spacer></vs-spacer>
                      <h3 class="mx-3 item-card-profit">{{ $t('Profit') }} {{  calculateProfit(product.value, product.discount)   | germanNumberFormat}}</h3>
                    </div>
                  </vx-card>
<!--                  <vx-card class="rounded-md"  v-bind:class="{ 'product-selected': selectedConfigIndex == index }"  @click="setProductConfigSelected(index)">-->
<!--                    <div class="vx-row vs-align-center text-center">-->
<!--                      <h1 class="mx-3 item-card-text">{{ product.value | germanNumberFormat }}</h1>-->
<!--                      <vs-spacer></vs-spacer>-->
<!--                      <h3 class="mx-3 item-card-profit">{{ $t('Profit') }} {{ calculateProfit(product.value, product.discount)  | germanNumberFormat}}</h3>-->
<!--                    </div>-->
<!--                  </vx-card>-->
                </div>
              </div>
              <div class="modal-content py-2 px-10 vx-row flex justify-center" >
                <vs-input size="large" class=" md:w-1/2 custom-input"
                          type="number" v-validate="'required'"
                          validate-on="change"
                          :label="$t('EnterBachNo')"
                          :placeholder="$t('EnterBachNo')"
                          v-model="giftCardBatchNo"/>

                <div class="pt-6">
                  <div class="vs-row flex justify-center px-4">
                    <vs-button size="large" class="ml-4  mr-4" color="danger" @click="onCloseProductDialog()">{{
                        $t('Close')
                      }}
                    </vs-button>
                    <vs-button size="large" class="ml-2" color="primary" @click="purchaseCard()">{{ $t('Activate') }}</vs-button>
                  </div>
                </div>
              </div>
              <div class="p-2"></div>
              <div class="flex justify-evenly w-full card-billing-text">
                <div class="vx-row">
                  <p>{{ $t('TotalBill') }} <span class="card-billing-info">{{ totalBill | germanNumberFormat }}</span>
                  </p>
                </div>
                <div class="vx-row" v-if="activeUserInfo.mask_seller_discount != 1">
                  <p>{{ $t('Discount') }} <span class="card-billing-info">{{ discount }} %</span>
                  </p>
                </div>
                <div class="vx-row" v-if="activeUserInfo.mask_seller_discount != 1">
                  <p>{{ $t('Profit') }} <span class="card-billing-info">{{ profit | germanNumberFormat }}</span></p>
                </div>
                <div class="vx-row" v-if="activeUserInfo.mask_seller_discount != 1">
                  <p>{{ $t('BuyingPrice') }} <span class="card-billing-info">{{ (totalBill - profit )| germanNumberFormat }}</span></p>
                </div>
              </div>

            </div>
          </div>
<!--          <div class="p-6 flex flex-centered" v-if="showPanInput">-->
<!--          <vs-row class="" vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12" >-->
<!--            <vs-col vs-sm="12" vs-lg="12">-->
<!--              <div class="gift-card-body">-->
<!--                  <h1 class="text-center gift-card-title">{{$t('GiftCards')}}</h1>-->
<!--                  <div class="py-4"></div>-->
<!--                  <div style="text-align: center;">-->
<!--                    <img class="gift-card-image" :src="selectedProduct.product_image"/>-->
<!--                    <p class="gift-card-amount">{{selectedProduct.product_configurations[selectedConfigIndex].value | germanNumberFormat}}</p>-->
<!--                  </div>-->
<!--                  <div class="py-4"></div>-->
<!--                <vs-input class="w-full" type="text" v-validate="'required'" validate-on="change" :label-placeholder="$t('EnterBachNo')" v-model="giftCardBatchNo"/>-->
<!--                <div class="py-4"></div>-->

<!--                  <div class="vs-row flex justify-center">-->
<!--                    <vs-button size="large" class="ml-4  mr-4" color="danger" @click="onCloseProductDialog()">{{-->
<!--                        $t('Close')-->
<!--                      }}-->
<!--                    </vs-button>-->
<!--                    <vs-button class="btn-padding" color="primary" @click="purchaseCard()">Activate</vs-button>-->
<!--                  </div>-->
<!--                  <div class="w-full text-center">-->
<!--                  </div>-->
<!--                  <div class="py-4"></div>-->
<!--                </div>-->
<!--            </vs-col>-->
<!--          </vs-row>-->
<!--          </div>-->
        </template>
        <template slot="footer"></template>
      </vx-modal>
    </div>

    <vx-modal v-if="showPurchaseDataModal" component-class="modal-container-7">
      <template slot="body">
        <div v-if="purchaseData">
          <div style="max-height: 500px; overflow-y: auto;">
            <div class="text-center status-card" v-if="purchaseData.message_type == 1">

              <div v-if="purchaseData">
                <receipt-print
                  v-on:close="onCloseProductDialog()"
                  ref="printCard"
                  :print-report-type="4" :purchase-data="purchaseData"></receipt-print>
              </div>
            </div>
            <div class="text-center status-card" v-if="purchaseData.message_type == 0">
              <div class="status-image">
                <svg style="margin: auto;display: block" xmlns="http://www.w3.org/2000/svg" width="241.43" height="241.43" viewBox="0 0 241.43 241.43">
                  <g id="Group_5694" data-name="Group 5694" transform="translate(-284 -284)">
                    <path id="Path_2360" data-name="Path 2360" d="M404.715,284C338.242,284,284,338.251,284,404.715S338.251,525.43,404.715,525.43,525.43,471.178,525.43,404.715,471.178,284,404.715,284Zm0,227.284A106.569,106.569,0,1,1,511.284,404.715,106.689,106.689,0,0,1,404.715,511.284Zm0,0" fill="#ff000d"/>
                    <path id="Path_2361" data-name="Path 2361" d="M435,456.219A21.219,21.219,0,1,0,456.219,435,21.244,21.244,0,0,0,435,456.219Zm21.219-7.073a7.073,7.073,0,1,1-7.073,7.073,7.082,7.082,0,0,1,7.073-7.073Zm0,0" transform="translate(-79.797 -79.797)" fill="#ff000d"/>
                    <path id="Path_2362" data-name="Path 2362" d="M576.219,435a21.219,21.219,0,1,0,21.219,21.219A21.244,21.244,0,0,0,576.219,435Zm0,28.293a7.073,7.073,0,1,1,7.073-7.073,7.082,7.082,0,0,1-7.073,7.073Zm0,0" transform="translate(-143.212 -79.797)" fill="#ff000d"/>
                    <path id="Path_2363" data-name="Path 2363" d="M431.22,377.072a7.069,7.069,0,0,0-10,0l-14.146,14.146a7.072,7.072,0,0,0,10,10l14.146-14.146a7.069,7.069,0,0,0,0-10Zm0,0" transform="translate(-63.943 -48.09)" fill="#ff000d"/>
                    <path id="Path_2364" data-name="Path 2364" d="M627.074,377.072a7.072,7.072,0,0,0-10,10l14.146,14.146a7.072,7.072,0,0,0,10-10Zm0,0" transform="translate(-174.92 -48.09)" fill="#ff000d"/>
                    <path id="Path_2365" data-name="Path 2365" d="M442.073,555a7.073,7.073,0,0,0,0,14.146,49.568,49.568,0,0,1,49.512,49.512,7.073,7.073,0,1,0,14.146,0A63.731,63.731,0,0,0,442.073,555Zm0,0" transform="translate(-79.797 -143.212)" fill="#ff000d"/>
                  </g>
                </svg>
              </div>
              <h4>{{$t('TransactionFailed')}}</h4>
              <h3>{{ purchaseData.message }}</h3>
            </div>
          </div>
          <div class="vs-row flex justify-center mt-3" v-if="purchaseData.message_type != 1">
            <vs-button class="" color="primary" @click="onCloseProductDialog()">{{ $t('Close') }}</vs-button>
          </div>
        </div>
      </template>
    </vx-modal>
  </div>

</template>

<script>
import VxModal from '@/layouts/components/custom/VxModal'
import ReceiptPrint from '@/views/components/ReceiptPrint'

export default {
  name: 'GiftCards',
  components: {
    ReceiptPrint,
    VxModal,
  },
  data() {
    return {
      errorFetching: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Gift Cards', i18n: 'GiftCards', active: true },
      ],
      searchText: '',
      selectedAlpha: null,
      selectedAlphaCurrentX: 1,
      alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      selectedProduct: null,
      showProductDialog: false,
      totalBill: 0,
      discount: 0,
      profit: 0,
      selectedConfigIndex: null,
      giftCardBatchNo: '',
      showPanInput: false,
      showPurchaseDataModal: false,
      purchaseData: null,
    }
  },
  computed: {
    giftCards() {
      return this.$store.state.giftCards.giftCards.filter((c) => c.product_name.toLowerCase().startsWith(this.searchText.toLowerCase()) )
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    onProductSelected(p) {
      this.selectedProduct = p
      console.log(this.selectedProduct)
      this.showProductDialog = true
    },
    onCloseProductDialog() {
      this.showPurchaseDataModal = false
      this.showProductDialog = false
      this.selectedProduct = null
      this.showPanInput = false
      this.selectedConfigIndex = null
      this.giftCardBatchNo = ''
      this.purchaseData = null
      this.showPurchaseDataModal = false
      this.totalBill = 0
      this.discount = 0
      this.profit = 0
      this.fetchAllGiftCards();
    },
    setProductConfigSelected(index) {
      if (this.selectedConfigIndex == index) {
        this.selectedConfigIndex = null
      } else {
        this.selectedConfigIndex = index
      }
      this.calculateBill()
    },
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
        this.searchText = this.selectedAlphaCurrentX
      }
    },
    clearSelectedAlpha() {
      this.selectedAlpha = null
      this.selectedAlphaCurrentX = 1

      this.searchText = ''
    },
    calculateBill() {
      if (this.selectedConfigIndex == null) {
        this.totalBill = 0
        this.discount = 0
        this.profit = 0
      } else {
        this.totalBill = Number(this.selectedProduct.product_configurations[this.selectedConfigIndex].value)
        this.discount = Number(this.selectedProduct.product_configurations[this.selectedConfigIndex].discount)
        const percent = ((this.totalBill) * (this.discount / 100)).toFixed(2)
        this.profit = percent
      }
    },
    calculateProfit(amount, commission) {
      return (Number(amount) * (Number(commission) / 100)).toFixed(2)
    },
    showEntryOptions() {
      this.showPanInput = true
    },
    purchaseCard() {

      if (this.giftCardBatchNo == '') {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please fill batch no',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if(this.selectedConfigIndex == null) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select Denomination',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      const data = {
        product_configuration_id: this.selectedProduct.product_configurations[this.selectedConfigIndex].id,
        card_type: 1,
        pan_card: this.giftCardBatchNo,
        quantity: 1,
      }
      this.showProductDialog = false
      this.$vs.loading()
      this.$store.dispatch('giftCards/purchaseCard', data)
        .then((data) => {
          this.$vs.loading.close()
          this.purchaseData = data
          this.showPurchaseDataModal = true
          this.checkPrintCardPop()
        })
        .catch((error) => {
          console.log(error)
          this.onCloseProductDialog()
          this.$vs.loading.close()
          if (error.response.data) {
            this.purchaseData = error.response.data
            this.showPurchaseDataModal = true
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    checkPrintCardPop() {
      const showPrintPop = localStorage.getItem('show_print_pop') || 'false'
      if (showPrintPop != 'true') {
        setTimeout(() => {
          this.$refs.printCard.printCard()
        }, 1000)
      }
    },
    fetchAllGiftCards() {
      this.$vs.loading()
      this.$store.dispatch('giftCards/fetchGiftCards', {})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
  },
  created() {
    this.fetchAllGiftCards();
  },
}
</script>

<style scoped>
.modal-container {
  border-radius: 30px !important;
}
.card-image {
  max-height: 48px;
  object-fit: cover;
  margin: auto;
}

.modal-card-image {
  max-height: 62px;
}

.item-card-text {
  font-size: 40px;
  font-weight: bolder;
}

.item-card-amount {
  font-size: 36px;
  padding: 0 10px;
}

.card-billing-text {
  font-size: 24px;
  font-weight: bold;
}

.card-billing-info {
  font-weight: normal !important;
}
.item-card-text {
  font-size: 28px;
  font-weight: bolder;
}
.item-card-profit {
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  margin: auto;
  color: #2c2c2c;
}
.product-selected {
  background-color: #44D7B6 !important;
  color: #ffffff !important;
}
.product-selected .item-card-profit {
  color: #ffffff !important;
}
.product-selected .item-card-text {
  color: #ffffff !important;
}

.gift-card-title {
  font-size: 2.5rem;
  color: #0000008a;
}
p.gift-card-amount {
  margin: auto;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bolder;
  color: black;
}
img.gift-card-image {
  width: 200px;
  margin: auto;
}
</style>
